import React from 'react';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { AsyncHandler } from '@requity-homes/component-library';
import { setCookie, deleteCookie, getCookie } from 'cookies-next';
import {
  cookiesCustomerRedirectPath,
  cookiesIdToken,
  setCrossDomainCookie,
} from '@requity-homes/utils';
import { REFERRAL_CODE, COOKIE_UTM_KEY } from '../const';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function withAuthenticator(Component: any): (props: any) => JSX.Element {
  return function WrappedWithAuthenticator(props: any) {
    const router = useRouter();
    const [isAuthenticated, setIsAuthenticated] = React.useState<
      boolean | undefined
    >(undefined);

    React.useEffect(() => {
      Auth.currentAuthenticatedUser()
        .then(() => {
          setIsAuthenticated(true);
          deleteCookie(cookiesCustomerRedirectPath);

          const referralCode = getCookie(REFERRAL_CODE);
          if (referralCode) {
            deleteCookie(REFERRAL_CODE);
          }
          const cookieUtmKey = getCookie(COOKIE_UTM_KEY);
          if (cookieUtmKey) {
            deleteCookie(COOKIE_UTM_KEY);
          }
        })
        .catch(() => {
          // Parse the URL to get the path
          const url = new URL(window.location.href);
          const pathWithQuery = url.pathname + url.search;

          // expires in 30 days
          setCookie(cookiesCustomerRedirectPath, pathWithQuery, {
            expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
          });

          // User is not signed in, redirect to sign-in page
          return router.push('/auth/sign-in');
        });

      // Listen to token refresh events to update cookies
      Auth.currentSession().then((session) => {
        setCrossDomainCookie(
          cookiesIdToken,
          session.getIdToken().getJwtToken(),
        );
      });
    }, []);

    return (
      <AsyncHandler loading={isAuthenticated === undefined}>
        <Component {...props} />
      </AsyncHandler>
    );
  };
}
