import { useEffect, useState, useCallback } from 'react';
import Head from 'next/head';
import NextLink from 'next/link';

import {
  Text,
  Button,
  Link,
  CircularProgress,
  AsyncHandler,
} from '@requity-homes/component-library';

import {
  MainContent,
  PhaseOverview,
  PhaseHeading,
  PhaseDetails,
  PreApprovalDetails,
  RejectionReasons,
  PageWrapper,
} from '../components';
import {
  useApplicationDetailsQuery,
  ApplicationStatus,
  useApplicantInfoQuery,
} from '../graphql/generated';
import { withAuthenticator } from '../authentication';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import { CurrentUserInfo } from '../authentication/types';
import { DEFAULT_MEETING_LINK, HAS_SEEN_ALL_PAGES } from '../const';
import { deleteCookie, getCookie } from 'cookies-next';
import {
  cookiesFavoriteListings,
  getListingsEnvironmentBaseUrl,
  cookiesListingPortalRedirectPath,
  setCrossDomainCookie,
} from '@requity-homes/utils';

export default withAuthenticator(Index);

export function Index() {
  const [requiredDataLoaded, setRequiredDataLoaded] = useState(false);

  const { data, loading, error } = useApplicantInfoQuery({
    fetchPolicy: 'no-cache',
  });

  const [currentUserInfo, setCurrentUserInfo] = useState<CurrentUserInfo>();

  const router = useRouter();

  const applicationStatus = data?.customerInfo?.applicationStatus;

  const {
    data: applicationData,
    loading: loadingApplicationData,
    error: errorLoadingApplicationData,
  } = useApplicationDetailsQuery({
    skip:
      applicationStatus === undefined ||
      applicationStatus === ApplicationStatus.ApplicationStarted,
  });
  const hasCoApplicant = applicationData?.preApprovalApplication.hasCoApplicant;

  const [nextStep, setNextStep] = useState('');

  useEffect(() => {
    Auth.currentUserInfo().then((user) => {
      setCurrentUserInfo(user);
    });
  }, []);

  useEffect(() => {
    if (error || errorLoadingApplicationData) {
      setRequiredDataLoaded(true);
    }
  }, [error, errorLoadingApplicationData]);

  useEffect(() => {
    if (currentUserInfo?.attributes && applicationStatus) {
      if (data?.customerInfo.favoriteListings) {
        const listingIds = data?.customerInfo.favoriteListings.map(
          (listing) => listing.mlsNumber,
        );

        setCrossDomainCookie(
          cookiesFavoriteListings,
          JSON.stringify(listingIds),
        );
      }

      const listingPortalRedirectPath = getCookie(
        cookiesListingPortalRedirectPath,
      );

      if (listingPortalRedirectPath) {
        deleteCookie(cookiesListingPortalRedirectPath);
        const baseUrl = getListingsEnvironmentBaseUrl(
          process.env.NEXT_PUBLIC_STAGE,
        );

        window.location.href = baseUrl + listingPortalRedirectPath;
      }

      const shouldRouteOldUserToNewFlow =
        applicationStatus === ApplicationStatus.PreApprovalAccepted ||
        applicationStatus === ApplicationStatus.FullApprovalStarted;

      if (currentUserInfo.attributes['custom:approvalFlowVersion'] === '2') {
        // Co-Applicant Navigation
        if (
          data?.customerInfo.isCurrentUserCoApplicant &&
          data?.customerInfo?.coApplicantDropOffPoint !== HAS_SEEN_ALL_PAGES
        ) {
          router.push(data?.customerInfo?.coApplicantDropOffPoint);
        } else if (data?.customerInfo.isCurrentUserCoApplicant) {
          router.push('/v2/full-approval');
        }

        // Primary Applicant Navigation
        if (applicationStatus === ApplicationStatus.ApplicationStarted) {
          router.push('/v2/pre-qualification');
        } else if (
          applicationStatus === ApplicationStatus.PreApprovalAccepted
        ) {
          router.push('/v2/pre-qualification/approved');
        } else if (
          applicationStatus === ApplicationStatus.PreApprovalDenied ||
          applicationStatus === ApplicationStatus.NoLongerLooking
        ) {
          router.push('/v2/pre-qualification/denied');
        } else {
          router.push('/v2/full-approval');
        }
      } else if (shouldRouteOldUserToNewFlow) {
        router.push('/v2/full-approval/intro');
      } else {
        setRequiredDataLoaded(true);
      }
    }
  }, [applicationStatus, currentUserInfo, data, router]);

  useEffect(() => {
    const primaryApplicantEmploymentSubmitted =
      !!applicationData?.preApprovalApplication?.primaryApplicant
        .employmentDetails;
    const coApplicantEmploymentSubmitted =
      !!applicationData?.preApprovalApplication?.coApplicant?.employmentDetails;

    const primaryApplicantBankStatementUploaded =
      data?.customerInfo?.primaryApplicantBankStatementUploaded;
    const coApplicantBankStatementUploaded =
      data?.customerInfo?.coApplicantBankStatementUploaded;
    const primaryApplicantIncomeVerificationUploaded =
      data?.customerInfo?.primaryApplicantIncomeVerificationUploaded;
    const coApplicantIncomeVerificationUploaded =
      data?.customerInfo?.coApplicantIncomeVerificationUploaded;

    const employmentSubmitted =
      (!hasCoApplicant && primaryApplicantEmploymentSubmitted) ||
      (hasCoApplicant && coApplicantEmploymentSubmitted);
    const bankStatementSubmitted =
      (!hasCoApplicant && primaryApplicantBankStatementUploaded) ||
      (hasCoApplicant && coApplicantBankStatementUploaded);
    const incomeVerificationSubmitted =
      (!hasCoApplicant && primaryApplicantIncomeVerificationUploaded) ||
      (hasCoApplicant && coApplicantIncomeVerificationUploaded);

    if (incomeVerificationSubmitted) {
      setNextStep('IDENTITY_VERIFICATION');
    } else if (bankStatementSubmitted) {
      setNextStep('INCOME_VERIFICATION');
    } else if (employmentSubmitted) {
      setNextStep('BANK_STATEMENT_SUBMISSION');
    } else if (data?.customerInfo.creditReportConsentGiven) {
      setNextStep('EMPLOYMENT_DETAILS');
    }
  }, [hasCoApplicant, data?.customerInfo, applicationData, applicationStatus]);

  const getNextStageUrl = useCallback(() => {
    let page;
    switch (true) {
      case applicationStatus === ApplicationStatus.FullApprovalSubmitted:
        page = null;
        break;
      case nextStep === 'IDENTITY_VERIFICATION':
        page = '/application/identity-verification';
        break;
      case nextStep === 'INCOME_VERIFICATION':
        page = '/application/income-verification';
        break;
      case nextStep === 'BANK_STATEMENT_SUBMISSION':
        page = '/application/statements-upload';
        break;
      case nextStep === 'EMPLOYMENT_DETAILS':
        page = '/application/employment-details';
        break;
      case applicationStatus === ApplicationStatus.PreApprovalAccepted:
      case applicationStatus === ApplicationStatus.FullApprovalStarted:
        page = '/application/full-approval';
        break;
      default:
        page = null;
    }

    return page;
  }, [nextStep, applicationStatus]);

  const nextStage = getNextStageUrl();

  return (
    <PageWrapper>
      <AsyncHandler
        loading={loading || loadingApplicationData || !requiredDataLoaded}
        error={error}
        errorMessage="We were unable to get your application status. Please refresh the page or try again later."
      >
        <Head>
          <title>Requity Homes | Customer Portal</title>
        </Head>
        <MainContent>
          <div className="flex flex-col gap-6 md:flex-row flex-1 md:h-5/6">
            <div className="flex flex-col bg-grey-6 p-8 rounded-lg md:w-full lg:w-8/12 md:max-h-full overflow-y-auto md:mb-8">
              <div className="flex w-full justify-between border-teal-dark pb-6 border-l-2">
                <div className="w-11/12">
                  <PhaseOverview>
                    <PhaseHeading stepNumber={1} status="Complete">
                      Account Creation
                    </PhaseHeading>
                    <PhaseDetails>
                      <Text className="text-grey-2 ml-3">
                        Welcome to Requity Homes! An account has been created to
                        help track your home ownership journey with us.
                      </Text>
                    </PhaseDetails>
                  </PhaseOverview>
                </div>
                <div className="w-1/12">
                  <CircularProgress percent={100} />
                </div>
              </div>
              {(() => {
                switch (applicationStatus) {
                  case ApplicationStatus.ApplicationStarted:
                    return (
                      <>
                        <PreApprovalNotSubmitted />
                        <FullApprovalUnavailable />
                      </>
                    );
                  case ApplicationStatus.PreApprovalAccepted:
                  case ApplicationStatus.FullApprovalStarted:
                  case ApplicationStatus.EmploymentDetailsSubmitted:
                  case ApplicationStatus.IncomeVerificationDocumentsSubmitted:
                  case ApplicationStatus.BankStatementSubmitted:
                    return (
                      <>
                        <PreApprovalApproved />
                        <FullApprovalUnavailable
                          nextStage={nextStage}
                          nextStep={nextStep}
                          applicationStatus={applicationStatus}
                        />
                      </>
                    );
                  case ApplicationStatus.FullApprovalSubmitted:
                    return (
                      <>
                        <PreApprovalApproved />
                        <FullApprovalSubmitted />
                      </>
                    );
                  case ApplicationStatus.PreApprovalDenied:
                    return (
                      <>
                        <PreApprovalDenied />
                        <FullApprovalUnavailable />
                      </>
                    );
                }
              })()}

              <div className="flex w-full justify-between">
                <div className="w-11/12">
                  <PhaseOverview>
                    <PhaseHeading stepNumber={4} status="Unavailable">
                      Full Approval Review
                    </PhaseHeading>
                    <PhaseDetails>
                      <Text className="text-grey-2 ml-3">
                        Once your supporting documentation is submitted, one of
                        our underwriters will work with you to determine your
                        final home shopping budget so you can work with a
                        realtor to put an offer on your dream home.
                      </Text>
                    </PhaseDetails>
                  </PhaseOverview>
                </div>
                <div className="w-1/12">
                  <CircularProgress percent={0} />
                </div>
              </div>
            </div>
            <div className="hidden lg:flex lg:flex-col justify-end gap-6 p-8 pt-0 sm:gap-8 md:gap-10 lg:w-4/12">
              {applicationStatus === ApplicationStatus.ApplicationStarted && (
                <NextLink passHref href="/application/pre-approval">
                  <Button as="a" className="sm:self-center mx-0 w-32 sm:px-0">
                    Continue
                  </Button>
                </NextLink>
              )}
              {nextStage && (
                <NextLink passHref href={nextStage}>
                  <Button as="a" className="sm:self-center mx-0 w-32 sm:px-0">
                    Continue
                  </Button>
                </NextLink>
              )}
            </div>
          </div>
        </MainContent>
      </AsyncHandler>
    </PageWrapper>
  );
}

function PreApprovalNotSubmitted() {
  return (
    <div className="flex w-full justify-between border-teal-light pb-6 border-l-2">
      <div className="w-11/12">
        <PhaseOverview defaultOpen>
          <PhaseHeading stepNumber={2}>Pre-approval</PhaseHeading>
          <PhaseDetails>
            <Text className="text-grey-2 ml-3">
              During the pre-approval stage, you will provide details about your
              household, current financial situation, and desired location. At
              the end, you will know your home shopping budget without impacting
              your credit score.
            </Text>
            <NextLink passHref href="/application/pre-approval">
              <Button as="a" className="sm:self-start lg:hidden w-32 sm:px-0">
                Continue
              </Button>
            </NextLink>
          </PhaseDetails>
        </PhaseOverview>
      </div>
      <div className="w-1/12">
        <CircularProgress percent={30} />
      </div>
    </div>
  );
}

function PreApprovalApproved() {
  return (
    <div className="flex w-full justify-between pb-6 border-l-2 border-teal-dark">
      <div className="w-11/12">
        <PhaseOverview defaultOpen>
          <PhaseHeading stepNumber={2} status="Complete">
            Pre-approval
          </PhaseHeading>
          <PhaseDetails>
            <Text className="text-grey-2 ml-3">
              Congratulations! You are pre-approved! Here is our estimate of
              what you will be able to afford through our program.
            </Text>
            <PreApprovalDetails />
          </PhaseDetails>
        </PhaseOverview>
      </div>
      <div className="w-1/12">
        <CircularProgress percent={100} />
      </div>
    </div>
  );
}

function PreApprovalDenied() {
  return (
    <div className="flex w-full justify-between border-error-light pb-6 border-l-2">
      <div className="w-11/12">
        <PhaseOverview defaultOpen>
          <PhaseHeading stepNumber={2} status="Failed">
            Pre-approval
          </PhaseHeading>
          <PhaseDetails>
            <Text className="text-grey-2 ml-3">
              Unfortunately, you have not been approved at this time. Based on
              the information you provided to us, here is how you compare to the
              minimum requirements.
            </Text>

            <RejectionReasons />

            <Text className="text-grey-2 ml-3">
              If you forgot to include something that you feel will help your
              application, please reach out to us at{' '}
              <Link href="mailto:info@requityhomes.com">
                info@requityhomes.com
              </Link>
              . Otherwise, we encourage you to try again later if your situation
              changes. We&apos;ve helped many Requity home owners who were not
              initially approved.
            </Text>

            <NextLink passHref href="/application/pre-approval">
              <Button className="sm:self-center sm:w-52">Re-Apply</Button>
            </NextLink>

            <Button
              as="a"
              hierarchy="secondary"
              href="https://www.requityhomes.com/how-it-works"
              className="sm:self-center"
            >
              Learn more
            </Button>
          </PhaseDetails>
        </PhaseOverview>
      </div>
      <div className="w-1/12">
        <CircularProgress percent={30} />
      </div>
    </div>
  );
}

function FullApprovalUnavailable({
  nextStage,
  nextStep,
  applicationStatus,
}: {
  nextStage?: string;
  nextStep?: string;
  applicationStatus?: ApplicationStatus;
}) {
  const getProgressPercent = useCallback(() => {
    let progresss;
    switch (true) {
      case nextStep === 'BANK_STATEMENT_SUBMISSION':
        progresss = 60;
        break;
      case nextStep === 'INCOME_VERIFICATION':
        progresss = 80;
        break;
      case nextStep === 'IDENTITY_VERIFICATION':
        progresss = 90;
        break;
      case applicationStatus === ApplicationStatus.FullApprovalStarted:
        progresss = 10;
        break;
      default:
        progresss = 0;
    }

    return progresss;
  }, [applicationStatus, nextStep]);

  const progressPercent = getProgressPercent();

  return (
    <div className="flex w-full justify-between border-grey-5 pb-6 border-l-2">
      <div className="w-11/12">
        <PhaseOverview defaultOpen={!!nextStage}>
          <PhaseHeading stepNumber={3} status="Unavailable">
            Full Approval
          </PhaseHeading>
          <PhaseDetails>
            <Text className="text-grey-2 ml-3">
              During the full approval stage, you will provide documentation
              that supports the information you provided during the pre-approval
              stage. This may include items like credit reports, bank
              statements, or tax returns.
            </Text>
            {nextStage && (
              <div className="flex justify-center lg:hidden">
                <NextLink passHref href={nextStage}>
                  <Button
                    as="a"
                    className="sm:self-center mx-0 w-32 sm:px-0 px-0 mt-4 ml-6"
                  >
                    Continue
                  </Button>
                </NextLink>
              </div>
            )}
          </PhaseDetails>
        </PhaseOverview>
      </div>
      <div className="w-1/12">
        <CircularProgress percent={progressPercent} />
      </div>
    </div>
  );
}

function FullApprovalSubmitted() {
  return (
    <div className="flex w-full justify-between border-teal-dark pb-6 border-l-2">
      <div className="w-11/12">
        <PhaseOverview defaultOpen>
          <PhaseHeading stepNumber={3} status="Complete">
            Full Approval
          </PhaseHeading>
          <PhaseDetails>
            <Text className="text-grey-2 ml-3">
              Thank you for providing your information. We are so excited to
              help you get into your new home. Book a call with your Account
              Manager so we can verify your details and get you fully approved.
            </Text>

            <Button
              as="a"
              href={DEFAULT_MEETING_LINK}
              target="_blank"
              className="sm:self-start w-36 sm:px-0"
            >
              Book a call
            </Button>
          </PhaseDetails>
        </PhaseOverview>
      </div>
      <div className="w-1/12">
        <CircularProgress percent={100} />
      </div>
    </div>
  );
}
